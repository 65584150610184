import React from 'react'
import { ClipLoader } from 'react-spinners'

type spinnerProps = {
  extraClass?: string
  size?: number
  color?: string
  dataTestId?: string
}

const Spinner = ({
  extraClass = '',
  size = 18,
  color = '#000',
  dataTestId,
}: spinnerProps): JSX.Element => (
  <div className={extraClass} data-testid={dataTestId}>
    <ClipLoader loading size={size} color={color} />
  </div>
)

export default Spinner
